import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  IconButton,
  InputAdornment,
  Button,
  OutlinedInput,
  Link,
} from '@mui/material';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useAuth } from '../../../hooks/useAuth';
import { useSnackbar } from 'notistack';
import { loginCall, resendTfaCall, LoginParams } from '../../../api/auth';
import { FormErrorMessage } from '../FormErrorMessage';
import { Network } from '@capacitor/network';
import { Error } from '../../../types/error';
import { ProgressOverlay } from "../ProgressOverlay";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(5).required(),
});

const defaultValues = {
  email: '',
  password: '',
  tfa_code: undefined,
};

type Props = {
  onSuccess: () => void;
}

export const LoginForm = ({onSuccess}: Props) => {
  const [requiresTfa, setRequiresTfa] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { setTokenToLocalStorage } = useAuth();
  const { t } = useTranslation();
  
  useEffect(() => {
    (async () => {
      await Network.removeAllListeners();
    })();
  }, []);
  
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });
  
  const getError = (error: Error) => {
    return error?.data?.data?.errors?.['tfa.required'] || error?.data?.data?.errors?.['tfa.code'];
  };
  
  const onSubmit = (data: LoginParams, requiresTfa = false) => {
    setIsLoading(true);
    
    if (requiresTfa) {
      resendTfaCall(data)
        .then(() => {
          const message = 'A new code has been sent to your e-mail address or SMS';
          enqueueSnackbar(message, { variant: 'success' });
        })
        .catch((e) => {
          const error = getError(e);
          enqueueSnackbar(error, { variant: 'error' });
        })
        .finally(() => {
          setIsLoading(false);
        });
      return;
    }
    loginCall(data)
      .then((response) => {
        setTokenToLocalStorage(response);
        onSuccess();
      })
      .catch((e) => {
        if (e?.status === 307) {
          window.location.href = e?.data?.location;
        } else {
          const error = getError(e);
          const errorMessage = error
            ? `${error} The code has been sent to the e-mail address or SMS`
            : 'Incorrect email or password.';
          if (error) {
            setRequiresTfa(true);
          }
          enqueueSnackbar(errorMessage, { variant: 'error' });
          setIsLoading(false);
        }
      });
  };
  
  return (
    <>
      <ProgressOverlay open={isLoading} />
      <form noValidate autoComplete="off" onSubmit={handleSubmit((data) => onSubmit(data, false))}>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange, onBlur } }) => (
              <TextField
                autoFocus
                label="Email"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={Boolean(errors.email)}
                placeholder={t('loginEmailPlaceholder')}
              />
            )}
          />
          {errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel htmlFor="password" error={Boolean(errors.password)}>
            {t('loginPassword')}
          </InputLabel>
          <Controller
            name="password"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange, onBlur } }) => (
              <OutlinedInput
                value={value}
                onBlur={onBlur}
                label="Password"
                onChange={onChange}
                id="password"
                error={Boolean(errors.password)}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            )}
          />
          {errors.password && <FormErrorMessage>{errors.password.message}</FormErrorMessage>}
        </FormControl>
        {requiresTfa && (
          <>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Controller
                name="tfa_code"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    autoFocus
                    label="TFA Code"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={Boolean(errors.tfa_code)}
                    placeholder="TFA Code"
                  />
                )}
              />
              {errors.tfa_code && <FormErrorMessage>{errors.tfa_code.message}</FormErrorMessage>}
            </FormControl>
            <Button
              variant="text"
              color="secondary"
              onClick={handleSubmit((data) => onSubmit(data, true))}
            >
              {t('loginResendTfaCode')}
            </Button>
          </>
        )}
        <Box sx={{ mb: 4, display: 'flex', justifyContent: 'flex-end' }}>
          <Link href="forgot-password" underline="none" color="secondary">
            {t('loginForgotPassword')}
          </Link>
        </Box>
        <Button
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{
            mb: 4,
          }}
        >
          {t('loginButton')}
        </Button>
        <Box
          sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}
        >
          <Typography color="waterloo" sx={{ mr: 2 }}>
            {t('loginNewAccount')}
          </Typography>
          <Link
            href={process.env.REACT_APP_SIGN_UP_URL}
            underline="none"
            target="_blank"
            rel="noopener noreferrer"
            color="secondary"
          >
            {t('loginCreateAnAccount')}
          </Link>
        </Box>
      </form>
    </>
  );
};